import { create } from 'zustand'
import { OrderServiceEmployeeList } from '../queries/employees/type'

interface EmployeeStore {
  orderServiceEmployeeList?: OrderServiceEmployeeList
  setOrderServiceEmployeeList: (
    orderServiceEmployeeList?: OrderServiceEmployeeList
  ) => void
}

export const useEmployeeStore = create<EmployeeStore>()((set) => ({
  orderServiceEmployeeList: undefined,
  setOrderServiceEmployeeList(orderServiceEmployeeList) {
    set({ orderServiceEmployeeList })
  },
}))
