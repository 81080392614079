import Stars from '../../../../../packages/assets/images/stars.png';
import WallPaper from '../../../../../packages/assets/images/wallpaper.png';
import Diamond from '../../assets/images/diamond.png';
import { FormClub } from '../../components/Forms/club/FormClub';
import HeaderBar from '../../components/Headers/HeaderBar';

export const ClubPaymentIntervalToHuman: { [key: number]: string } = {
  1: 'mês',
  6: 'semestre',
  12: 'ano',
};

export const Club: React.FC = () => {
  return (
    <>
      <div className="bg-primary z-[-9] absolute w-full h-60 opacity-50" />

      <img
        src={Stars}
        alt="Imagem do clube"
        className="w-full h-60 z-[-8] object-cover absolute top-0 grayscale"
      />
      <img
        src={WallPaper}
        alt="Imagem do clube"
        className="w-full h-60 z-[-10] object-cover absolute top-0 grayscale"
      />

      <div className="w-full h-full space-y-2">
        <HeaderBar className="p-4 !mb-16" colorButton="bg-white"></HeaderBar>
        <div className="flex flex-col space-y-4 items-center">
          <div className="absolute top-5 flex flex-col space-y-2">
            <img
              src={Diamond}
              alt="Imagem do clube"
              className="h-14 w-14 z-[-8] self-center"
            />
            <div className="text-lg font-medium text-contrastText">
              Clubes de benefícios
            </div>
          </div>
          <div className="bg-gray-100 p-4 h-screen w-full rounded-t-2xl">
            <FormClub />
          </div>
        </div>
      </div>
    </>
  );
};
