import CollapsibleItem from '../../../components/Collapse/CollapsibleItem';
import ListSchedulings from '../../../components/Lists/ListSchedulings';
import { useCartStore } from '../../../stores/cart';
import { useOrderDetails } from '../../../stores/order-details';
import { formatTime } from '@onbeefapp/constants';
import { StepType } from '@onbeefapp/constants';
import React from 'react';

interface Props {}

const Scheduling: React.FC<Props> = () => {
  const { currentStep, setCurrentStep, setAction } = useOrderDetails();

  const deliveryTime = useCartStore((state) => state.deliveryTime);

  return (
    <CollapsibleItem
      active={currentStep === StepType.SCHEDULING}
      onClick={() => {
        const step =
          currentStep === StepType.SCHEDULING
            ? StepType.NONE
            : StepType.SCHEDULING;
        setCurrentStep(step);
        setAction(() => {
          setCurrentStep(StepType.FINISHED);
          window.scrollTo(0, 0);
        });
      }}
      title={
        <div className="flex flex-row items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <div className="flex flex-col justify-start">
            <div className="ml-2 font-bold text-sm">Tempo de entrega</div>
            {/* <div className="ml-2 font-bold text-sm">Agende seu pedido</div> */}
            <div className="ml-2 text-sm font-normal  text-gray-500">
              {deliveryTime ? formatTime(deliveryTime) : 'Entrega padrão'}
            </div>
          </div>
        </div>
      }
    >
      <ListSchedulings />
    </CollapsibleItem>
  );
};

export default Scheduling;
