import * as Yup from 'yup'

export function mergeSchema(...schemas: Yup.ObjectSchema<any>[]) {
  const [first, ...rest] = schemas

  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas?.concat(schema),
    first
  )

  return merged || Yup.object().shape({})
}

export function isWhatsappExtension() {
  return window.location.hostname.includes('extensao')
}

export const isPhoneNumber = (text: string) => {
  const phoneRegex = /^\+?[1-9]\d{1,14}$/
  return phoneRegex.test(text.replace(/\s|-/g, ''))
}
