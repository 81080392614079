import { Typography } from '@material-tailwind/react';
import { TextArea } from '@onbeef/components/input';
import { Stars } from '@onbeef/components/stars';
import { Toast } from '@onbeefapp/constants';
import { AxiosError } from 'axios';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpin from '../../../components/LoadingSpin';
import SkeletonRateOrder from '../../../components/Skeleton/SkeletonRateOrder';
import {
  useCheckHasUnloggedAssessment,
  useGetMotives,
  useRegisterUnloggedAssessment,
} from '../../../queries/my-orders';
import { Motive } from '../../../queries/my-orders/types';
import { useMerchantStore } from '../../../stores/merchant';
import { CheckIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

interface UnlogedRatingForm {
  readonly: boolean;
  comments: string;
  motives: string[];
  stars: number;
}

const UnloggedRating: React.FC = () => {
  const navigate = useNavigate();
  const { phone, code } = useParams();

  const merchant = useMerchantStore((state) => state.merchant);

  const {
    data: hasAssessmentResponse,
    error: hasAssessmentError,
    isLoading: hasAssessmentLoading,
  } = useCheckHasUnloggedAssessment(phone, code);
  const { data: motives, isLoading: motivesLoading } = useGetMotives();

  const isLoading = motivesLoading || hasAssessmentLoading;

  const { mutateAsync: registerAssement, isLoading: isRegisterLoading } =
    useRegisterUnloggedAssessment();

  const methods = useForm<UnlogedRatingForm>({
    defaultValues: { motives: [] },
  });

  const readonly = methods.watch('readonly');
  const stars = methods.watch('stars');
  const selectedMotives = methods.watch('motives');

  const handleSelectMotive = (motive: Motive) => {
    if (readonly) return;
    if (selectedMotives?.includes(motive.id)) {
      methods.setValue(
        'motives',
        selectedMotives.filter((m) => m !== motive.id),
      );
    } else {
      methods.setValue('motives', [...(selectedMotives || []), motive.id]);
    }
  };

  const onSubmit = async (data: UnlogedRatingForm) => {
    if (!phone || !code || !merchant) return;

    try {
      const res = await registerAssement({
        merchant_id: merchant.id,
        phone: phone,
        order_code: code,
        rating: data.stars,
        motives: data.motives,
        comments: data.comments || undefined,
        type: 'ORDER',
      });
      res.message && Toast.success(res.message);
      navigate('/');
    } catch (error) {
      const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
        ?.error;
      msgs?.forEach((msg) => Toast.error(msg));
    }
  };

  React.useEffect(() => {
    if (hasAssessmentResponse && hasAssessmentResponse.has_assessment) {
      setTimeout(() => {
        navigate('/');
      }, 4000);
    }
  }, [hasAssessmentResponse]);

  React.useEffect(() => {
    if (hasAssessmentError) {
      const msgs = (hasAssessmentError as AxiosError<{ error: string[] }>)
        ?.response?.data?.error;
      msgs?.forEach((msg) => Toast.error(msg));
      navigate('/');
    }
  }, [hasAssessmentError]);

  return (
    <div className="flex flex-col p-2">
      {isLoading ? (
        <SkeletonRateOrder readonly={readonly} />
      ) : hasAssessmentResponse?.has_assessment ? (
        <div className="w-full h-screen flex flex-col items-center justify-center gap-4">
          <CheckIcon className="w-28 h-28 text-green-400" />
          <span className="font-semibold text-2xl text-center">
            {hasAssessmentResponse?.message}
          </span>
        </div>
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex items-center justify-center"
          >
            <div className="flex flex-col p-4 mt-4 w-[100%] md:w-[60%] align-center space-y-6">
              <div>
                <Typography variant="h5" placeholder={''}>
                  {readonly ? 'Sua avaliação' : 'Avalie seu pedido'}
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography variant="h6" placeholder={''}>
                  O que você achou?
                </Typography>
                <Typography variant="small" placeholder={'avalie'}>
                  Avalie de 1 a 5 estrelas para classificar.
                </Typography>
                <Stars
                  value={stars}
                  handler={(v) => methods.setValue('stars', v)}
                  readonly={readonly}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <Typography variant="h6" placeholder={'label'}>
                  O que pode melhorar?
                </Typography>
                <div className="flex flex-wrap w-full gap-1">
                  {motives?.map((motive) => {
                    const selected = selectedMotives?.includes(motive.id);
                    return (
                      <button
                        type="button"
                        key={motive.id}
                        className={cx(
                          'px-4 py-2 font-medium transition duration-300 border rounded-full outline-none',
                          selected
                            ? 'bg-primary text-contrastText'
                            : 'bg-white text-black border-gray-300',
                        )}
                        onClick={() => {
                          handleSelectMotive(motive);
                        }}
                      >
                        {motive.name}
                      </button>
                    );
                  })}
                </div>
              </div>
              <div>
                <Typography placeholder={'comentario label'} variant="h6">
                  Deixar comentário
                </Typography>
                <TextArea
                  name="comments"
                  className="mt-2 w-full h-[100px] text-base resize-none outline-none rounded-md px-2 py-1 border border-gray-300"
                  placeholder="Conte-nos o que aconteceu (opcional)"
                  disabled={readonly}
                />
              </div>
              {!readonly && (
                <div className="flex items-center justify-center w-full">
                  <button
                    type="submit"
                    disabled={isRegisterLoading}
                    className="flex items-center justify-center w-full px-6 py-2 font-medium rounded-lg bg-primary text-contrastText"
                  >
                    {isRegisterLoading ? <LoadingSpin /> : 'Avaliar'}
                  </button>
                </div>
              )}
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default UnloggedRating;
