import { DateTimePicker } from '@onbeef/components/input'
import { calculateSchedulingDisplayValue, Toast } from '@onbeefapp/constants'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import CardScheduling from '../../../../catalogo/src/components/Cards/CardScheduling'
import { OrderDetailsContext } from '../../../../catalogo/src/contexts/OrderDetailsContext'
import { SchedulingsResponse } from '../../../../catalogo/src/queries/order/types'
import { useCartStore } from '../../../../catalogo/src/stores/cart'

type Props = {
  data: SchedulingsResponse
}

export const ExtensionScheduling: React.FC<Props> = ({ data }) => {
  const deliveryTime = useCartStore((state) => state.deliveryTime)

  const discount = data?.discounts?.[0]
  const defaultDay = data.daysAvailable[0]
  const defaultTime = defaultDay?.times?.[0]

  const defaultSelected = deliveryTime
    ? deliveryTime.date === data.daysAvailable[0]?.date &&
      deliveryTime.time_interval.start === defaultTime?.start
    : defaultTime?.default || false

  return (
    <>
      {defaultDay && defaultTime && (
        <CardScheduling
          baseDiscount={discount}
          date={defaultDay?.date}
          selected={defaultSelected}
          time={defaultTime}
          width="48%"
        />
      )}

      <CustomCardScheduling data={data} defaultSelected={defaultSelected} />
    </>
  )
}

interface CustomSchedulingForm {
  datetime: string
}

const getNextSchedule = (data: SchedulingsResponse) => {
  let day = data.daysAvailable[0]
  let time = day?.times[1]
  if (time) {
    return { day, time }
  }
  day = data.daysAvailable[1]
  time = day?.times[0]
  if (time) {
    return { day, time }
  }
}

const CustomCardScheduling: React.FC<Props & { defaultSelected: boolean }> = ({
  data,
  defaultSelected,
}) => {
  const nextSchedule = getNextSchedule(data)

  const methods = useForm<CustomSchedulingForm>({
    defaultValues: { datetime: '' },
  })

  const deliveryTime = useCartStore((state) => state.deliveryTime)

  const setDeliveryTime = useCartStore((state) => state.setDeliveryTime)

  const {
    frete,
    isFreteGratis,
    isACombinar,
    freteValue,
    withFreteGratisDiscount,
  } = React.useContext(OrderDetailsContext)

  const selected = !defaultSelected
  const datetime = methods.watch('datetime')

  const handleSetAsDeliveryTime = (datetime: string) => {
    const [date, time] = datetime?.split('T')
    if (!date || !time) {
      setDeliveryTime(undefined)
      Toast.error('Selecione o hórario e a data do agendamento primeiro!')
      return
    }
    setDeliveryTime(
      date,
      {
        start: time,
        end: null,
        default: false,
        discount: nextSchedule?.time?.discount || null,
      },
      freteValue
    )
  }

  React.useEffect(() => {
    let value = ''
    if (deliveryTime) {
      value = `${deliveryTime.date}T${deliveryTime.time_interval.start}`
    } else {
      value = `${nextSchedule?.day?.date}T${nextSchedule?.time?.start}`
    }
    methods.setValue('datetime', value)
  }, [])

  const getTodayAsMinDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}T00:00`
  }

  const discount = data?.discounts?.[0]

  return (
    <FormProvider {...methods}>
      <form
        className={`flex flex-col gap-2 p-2 border-2 border-[#d4d4d4] rounded-lg flex-shrink-0 w-[48%] cursor-pointer ${
          selected && '!border-[#0c8a47]'
        }`}
        onClick={() => handleSetAsDeliveryTime(datetime)}
      >
        <div className="flex flex-row w-full space-x-2">
          <p className="font-bold text-sm">Agendada</p>
          {!isACombinar && discount && (
            <p className="uppercase bg-[#fad2d2] text-[#700000] p-1 py-[2px] rounded-xl font-bold text-sm ml-1">
              - {discount.percent}%
            </p>
          )}
        </div>
        <p className="font-medium text-xs whitespace-nowrap flex flex-col gap-1">
          <DateTimePicker
            name="datetime"
            min={getTodayAsMinDate()}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => handleSetAsDeliveryTime(e.target.value)}
            step={900}
          />
        </p>
        <div className="flex w-full mt-2">
          <p
            className={`font-medium text-sm ${
              frete === 'Frete Grátis' ||
              (frete === 'À Combinar' &&
                'bg-[#d2fadf] px-1 !text-sm rounded-md font-semibold text-[#007023]')
            }`}
          >
            {calculateSchedulingDisplayValue({
              discount,
              frete,
              freteValue,
              isACombinar,
              isFreteGratis,
              withFreteGratisDiscount,
            })}
          </p>
          {selected && (
            <div className="flex ml-2 flex-shrink justify-end text-[#0c8a47]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  )
}
