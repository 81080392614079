import { Select } from '@onbeef/components/input';
import { useEmployeeStore } from '../../../../lojista/src/stores/employee';
import { ORDER_STATUS } from '@onbeefapp/constants';
import { useOrderDetails } from '../../stores/order-details';

export const ListEmployees: React.FC = () => {
  const employeeList = useEmployeeStore(
    (state) => state.orderServiceEmployeeList,
  );

  const { employeeID, setEmployeeID } = useOrderDetails((state) => ({
    employeeID: state.employeeID,
    setEmployeeID: state.setEmployeeID,
  }));

  const employees =
    employeeList && employeeList[ORDER_STATUS.PENDING]
      ? employeeList[ORDER_STATUS.PENDING]!.roles.map(
          (role) => role.employees,
        ).flat()
      : [];

  return (
    employees.length > 0 && (
      <div className="w-full flex flex-col mt-2">
        <span className="text-base font-semibold text-left py-2">
          Funcionário responsável
        </span>
        <Select
          name="employee_id"
          options={employees.map((employee) => ({
            value: employee.id,
            label: employee.name,
          }))}
          defaultValue={employeeID}
          onSelectOption={(opt) => {
            opt.value && setEmployeeID(opt.value as string);
          }}
          placeholder="Selecione o funcionário responsável"
        />
      </div>
    )
  );
};
