import { ProductType } from '@onbeefapp/constants';
import { OrderPayload } from '../queries/order/types';
import { ProductCartType } from '../stores/cart';
import { useComplementsStore } from '../stores/complements';
import { useKitsStore } from '../stores/kits';
import { getFinalQuantity } from './CartUtils';
import { formatKitsPayload } from './KitsUtils';

export class CartProductsMapper {
  static toPayload(products: ProductCartType[]): OrderPayload['items'] {
    const getKitItems = useKitsStore.getState().getKitItems;
    const getComplements = useComplementsStore.getState().getComplements;

    return Object.values(products).map((product) => {
      const kitItems = getKitItems(product.id);
      const complements = getComplements(product.id);
      const quantity = getFinalQuantity(product);

      return {
        product_id: product.id,
        quantity: quantity,
        discount: 0,
        comments: product.comments ?? '',
        package_quantity: product.package_quantity,
        complements: complements.map((complement) => {
          const quantity =
            complement.type === ProductType.KG && complement.items_kg === 1
              ? complement.quantity * 100
              : complement.quantity;
          return {
            id: complement.id,
            quantity,
          };
        }),
        cuts: product.selectedCuts,
        items: kitItems ? formatKitsPayload(kitItems) : undefined,
      };
    });
  }
}
