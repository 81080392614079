import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import {
  Gender,
  genderOptions,
  maskCPF,
  maskDate,
  maskPhone,
} from '@onbeefapp/constants'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { GroupRadioButton, RadioButton, TextInput } from '../input'

export interface ICustomerForm {
  with_name_phone: boolean
  with_password: boolean
  have_account: boolean
  without_cpf: boolean
  no_password_confirmation: boolean
  name?: string
  phone?: string
  email?: string
  taxpayer_id_number?: string
  birthday?: string
  gender?: Gender
  password?: string
  password_confirmation?: string
  age_group?: string
}

export interface CustomerFormProps {
  haveAccount?: boolean
  withPassword?: boolean
  phoneDisabled?: boolean
  emailDisabled?: boolean
  cpfDisabled?: boolean
  noPasswordConfirmation?: boolean
}

export const CustomerForm: React.FC<CustomerFormProps> = ({
  withPassword = true,
  haveAccount = false,
  phoneDisabled = false,
  emailDisabled = false,
  cpfDisabled = false,
  noPasswordConfirmation = false,
}) => {
  const methods = useFormContext<ICustomerForm>()
  const [hiddenPassword, setHiddenPassword] = React.useState(true)
  const [hiddenPasswordConfirmation, setHiddenPasswordConfirmation] =
    React.useState(true)

  React.useEffect(() => {
    methods.reset({
      have_account: haveAccount,
      without_cpf: haveAccount,
      with_password: withPassword,
      no_password_confirmation: noPasswordConfirmation,
    })
  }, [haveAccount, withPassword, noPasswordConfirmation])

  return (
    <div className="w-full flex flex-col space-y-2">
      <TextInput name="name" customLabel="Nome" />
      <TextInput
        name="phone"
        customLabel="Celular"
        inputMode="numeric"
        autoComplete="phone"
        maxLength={15}
        placeholder="(00) 00000-0000"
        disabled={haveAccount || phoneDisabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          methods.setValue('phone', maskPhone(e.target.value))
          methods.trigger('phone')
        }}
      />
      <TextInput name="email" customLabel="Email" disabled={emailDisabled} />
      <GroupRadioButton
        alignment={'horizontal'}
        className="flex flex-col gap-1"
      >
        <span>Sexo</span>
        <div className="w-full flex gap-2 flex-col md:flex-row">
          {genderOptions.map((opt) => (
            <RadioButton
              name={'gender'}
              label={opt.label}
              value={opt.value}
              id={opt.value}
            />
          ))}
        </div>
      </GroupRadioButton>
      <TextInput
        name="birthday"
        customLabel="Data de aniversário"
        onChange={(e) => {
          methods.setValue('birthday', maskDate(e.target.value))
          methods.trigger('birthday')
        }}
      />
      <TextInput
        name="taxpayer_id_number"
        customLabel="CPF"
        inputMode="numeric"
        autoComplete="cpf"
        onChange={(e) => {
          methods.setValue('taxpayer_id_number', maskCPF(e.target.value))
          methods.trigger('taxpayer_id_number')
        }}
        disabled={cpfDisabled}
      />
      {withPassword && (
        <>
          <TextInput
            name="password"
            customLabel="Senha"
            type={hiddenPassword ? 'password' : 'text'}
            className={`mb-1 rounded-b-lg text-base f-16 transition-all duration-700 ease-in-out`}
            endIcon={
              <button
                type="button"
                onClick={() => setHiddenPassword(!hiddenPassword)}
              >
                {hiddenPassword ? (
                  <EyeIcon className={`h-6 w-6 mr-2 mb-1 text-gray-600`} />
                ) : (
                  <EyeSlashIcon className={`h-6 w-6 mr-2 mb-1 text-gray-600`} />
                )}
              </button>
            }
          />
          {!noPasswordConfirmation && (
            <TextInput
              name="password_confirmation"
              customLabel="Confirmação de senha"
              type={hiddenPasswordConfirmation ? 'password' : 'text'}
              className={`mb-1 rounded-b-lg text-base f-16 transition-all duration-700 ease-in-out`}
              endIcon={
                <button
                  type="button"
                  onClick={() =>
                    setHiddenPasswordConfirmation(!hiddenPasswordConfirmation)
                  }
                >
                  {hiddenPasswordConfirmation ? (
                    <EyeIcon className={`h-6 w-6 mr-2 mb-1 text-gray-600`} />
                  ) : (
                    <EyeSlashIcon
                      className={`h-6 w-6 mr-2 mb-1 text-gray-600`}
                    />
                  )}
                </button>
              }
            />
          )}
        </>
      )}
    </div>
  )
}
