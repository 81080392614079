import CardProduct from '../../components/Cards/CardProduct';
import SkeletonProduct from '../../components/Skeleton/SkeletonProduct';
import { Product } from '@onbeefapp/constants';
import CardViewAllProducts from '../Cards/CardViewAllProducts';
import * as React from 'react';
import HeinekenCard from '../../assets/heineken_card.png';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

interface ListScrollProductProps {
  products: Product[];
  id: string;
  productsLimit: number;
  productsMore: number;
  slug?: string;
}

const ListScrollProduct: React.FC<ListScrollProductProps> = ({
  products,
  id,
  productsLimit,
  productsMore,
  slug,
}) => {
  const navigate = useNavigate();

  const isHeineken = slug === 'se-tem-churras-tem-heineken';

  return (
    <div
      className={cx(
        'space-x-3 flex w-full mx:auto overflow-x-scroll overflow-y-hidden pt-2',
        isHeineken ? '' : 'snap-x snap-mandatory',
      )}
      id={`scroll${id}`}
    >
      <SkeletonProduct>
        {isHeineken && (
          <button
            className="flex shrink-0 flex-col gap-y-2 mb-3 relative"
            onClick={() => navigate(`/category/${slug}`)}
          >
            <img
              className="rounded-lg w-full h-full object-center"
              src={HeinekenCard}
              alt="Heineken"
            />
          </button>
        )}
        {products.slice(0, productsLimit).map((product, i) => (
          <CardProduct key={`cardProduct${product.id}${i}`} product={product} />
        ))}
        {productsMore > 0 && (
          <CardViewAllProducts quantity={productsMore} category_slug={slug} />
        )}
      </SkeletonProduct>
    </div>
  );
};

export default ListScrollProduct;
