import { diff } from './time'
import {
  OrderSchedule,
  SchedulingDiscount,
} from '../../../../apps/catalogo/src/queries/order/types'
import { Promotion } from '@onbeefapp/constants'
import { format } from 'date-fns'
import { currencyFormat } from './currency-format'

export const formatTime = (time: OrderSchedule) => {
  const [year, mounth, day] = time.date.split('-').map(Number)
  if (!year || !mounth || !day) return ''
  const d = new Date(year, mounth - 1, day)
  const dayOfWeek = d.toLocaleDateString('pt-BR', { weekday: 'long' })
  const dayOfWeekFirstLetter = dayOfWeek[0]?.toUpperCase()
  const dayOfWeekRest = dayOfWeek.slice(1)

  const timeFormatted = time.time_interval.end
    ? `entre ${formatHour(time.time_interval.start)}:00 e ${formatHour(
        time.time_interval.end
      )}:00 horas`
    : `as ${time.time_interval.start}`

  return `${dayOfWeekFirstLetter}${dayOfWeekRest}, ${timeFormatted}`
}

export const formatHour = (date: string) => {
  const hour = date.split(':')[0]
  if (!hour) return ''
  return hour.startsWith('0') ? hour[1] : hour
}

export const formatHour2 = (hour: string): string => {
  const splitHour = hour.split(':')
  if (splitHour.length !== 3) {
    throw new Error('Hora no formato inválido')
  }
  return `${splitHour[0]}:${splitHour[1]}h`
}

export const getTodayWeekDay = () => {
  return format(new Date(), 'iii').toUpperCase()
}

export const formatDate = (date: string) => {
  const today = new Date()
  const [year, month, day] = date.split('-').map(Number)
  if (!year || !month || !day) return ''
  const months = [
    'Jan.',
    'Fev.',
    'Mar.',
    'Abr.',
    'Mai.',
    'Jun.',
    'Jul.',
    'Ago.',
    'Set.',
    'Out.',
    'Nov.',
    'Dez.',
  ]

  if (
    today.getFullYear() === year &&
    today.getMonth() === month - 1 &&
    today.getDate() === day
  ) {
    return 'Hoje'
  } else {
    return `${months[month - 1]} ${day}`
  }
}

export const calculateSchedulingDiscount = (
  discount: SchedulingDiscount,
  frete: number
) => {
  let result = frete
  if (discount) {
    result = (result * discount.percent) / 100
  }
  return result
}

export const promotionStarted = (promotion?: Promotion): boolean => {
  if (!promotion?.start_at) {
    return true
  }
  return diff(new Date(promotion.start_at), new Date()).seconds <= 0
}

export const promotionExpired = (promotion?: Promotion): boolean => {
  if (!promotion?.expired_at) {
    return false
  }
  return (
    promotion.expired_at != null &&
    diff(new Date(promotion.expired_at), new Date()).seconds <= 0
  )
}

export interface CalculateSchedulingDisplayValueParams {
  isACombinar: boolean
  isFreteGratis: boolean
  withFreteGratisDiscount: boolean
  freteValue?: number
  frete?: string
  discount?: SchedulingDiscount | null
}

export const calculateSchedulingDisplayValue = ({
  frete,
  isACombinar,
  freteValue,
  isFreteGratis,
  withFreteGratisDiscount,
  discount,
}: CalculateSchedulingDisplayValueParams) => {
  let result: string | undefined

  if (
    ((isFreteGratis || isACombinar) && !withFreteGratisDiscount) ||
    freteValue == null
  ) {
    result = frete
  } else if (discount?.percent) {
    result = currencyFormat(
      freteValue - calculateSchedulingDiscount(discount, freteValue)
    )
  } else {
    result = currencyFormat(freteValue)
  }
  return result
}
