import {
  OrderDetails,
  ProductType,
  calculateOrderComplementTotalAndQuantityV1,
  calculateOrderItemTotalAndQuantityV1,
  currencyFormat,
  formatPackageQuantityComments,
} from '@onbeefapp/constants';
import { WppComplement } from '@onbeefapp/constants/src/utils/Whatsapp/Interfaces';
import React from 'react';
import NoPhoto from '../../../assets/images/no-photos.png';
import { useMerchantStore } from '../../../stores/merchant';

interface OrderDetailItemsProps {
  items?: OrderDetails['items'];
  withImage?: boolean;
}

const OrderDetailItemsImage: React.FC<OrderDetailItemsProps> = ({
  items,
  withImage = false,
}) => {
  const merchant = useMerchantStore((state) => state.merchant);

  if (!items) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-4 w-full">
      {items.map((item) => {
        const isProductReward = item.loyalty_programme_reward_id != null;
        const { quantity, total, item_type } =
          calculateOrderItemTotalAndQuantityV1(item);

        if (!item.coins) {
          return (
            <React.Fragment key={item.id}>
              <div className="flex flex-row gap-2">
                {withImage && (
                  <img
                    className="object-cover w-[50px] h-[50px] rounded-lg"
                    src={
                      item.product.media[0]?.file_url ||
                      merchant?.logo ||
                      NoPhoto
                    }
                    alt=""
                    loading="lazy"
                  />
                )}
                <div className="flex flex-col gap-1 w-full">
                  <span className="font-semibold">{item.product?.name}</span>
                  <span>
                    {isProductReward ? (
                      <span className="text-green-600">Brinde</span>
                    ) : (
                      <div className="flex flex-col">
                        {quantity}
                        {item_type}
                      </div>
                    )}
                  </span>
                  <div>
                    {item.product.type === ProductType.KIT &&
                      item.product.kit_type != null &&
                      item.items.categories && (
                        <div className="flex flex-col font-semibold text-xs text-gray-500">
                          {item.items.categories.map((cat) => {
                            return (
                              <React.Fragment key={cat.id}>
                                {cat?.products.map((item) => {
                                  return (
                                    <>
                                      <span>
                                        {item.quantity} {item.product.name}
                                      </span>
                                      {item.cuts && (
                                        <div>
                                          {item.cuts.map((cut) => {
                                            return (
                                              <span className="text-gray-400">
                                                {cut.pivot.grams}g {cut.name}
                                              </span>
                                            );
                                          })}
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      )}
                    {item.cuts.map((cut) => {
                      return (
                        <div className="flex flex-col font-semibold text-xs text-gray-500 text-left">
                          {cut.pivot.grams}g {cut?.name}
                          {(cut.pivot?.comments ||
                            cut.pivot?.package_quantity) && (
                            <span className="text-left text-[#aaaaaa]">
                              {formatPackageQuantityComments(
                                cut.pivot.comments,
                                cut.pivot.package_quantity,
                              )}
                            </span>
                          )}
                        </div>
                      );
                    })}
                    <div className="flex flex-col">
                      {item.complements?.map((complement) => {
                        const { price, quantity, item_type } =
                          calculateOrderComplementTotalAndQuantityV1(
                            item,
                            complement as unknown as WppComplement,
                          );

                        return (
                          <React.Fragment key={complement.id}>
                            <div className="col-span-11">
                              <p className="font-semibold text-xs text-gray-500">
                                <span>{`${quantity}${item_type} ${
                                  complement.name
                                }${
                                  complement.pivot.price &&
                                  complement.pivot.price > 0
                                    ? ` - ${currencyFormat(price)}`
                                    : ''
                                }`}</span>
                              </p>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                  <span className="font-semibold">
                    {currencyFormat(isProductReward ? 0 : total)}
                  </span>
                  {item.comments ? (
                    <div className="w-full">
                      <p className="text-gray-600">
                        <span>{item.comments}</span>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default OrderDetailItemsImage;
