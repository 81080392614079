import { DeliveryType, StepType, WherePaymentType } from '@onbeefapp/constants';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type OrderDetailsStore = {
  currentStep: StepType;
  selectedPaymentMethod: string | undefined;
  deliveryInstructions: string;
  deliveryType: DeliveryType;
  wherePaymentType: WherePaymentType | undefined;
  deliveryPrice: number | undefined;
  deliveryCoupon: string | undefined;
  withoutMoneyBack: boolean;
  employeeID?: string;
  action: () => void;
  setAction: (action: () => void) => void;
  setCurrentStep: (step: StepType) => void;
  setDeliveryInstructions: (instructions: string) => void;
  setSelectedPaymentMethod: (paymentMethod: string | undefined) => void;
  setDeliveryType: (deliveryType: DeliveryType) => void;
  setWherePaymentType: (wherePaymentType: WherePaymentType) => void;
  setDeliveryPrice: (price: number | undefined) => void;
  setDeliveryCoupon: (coupon: string | undefined) => void;
  setWithoutMoneyBack: (withoutMoneyBack: boolean) => void;
  setEmployeeID: (employeeID?: string) => void;
};

export const useOrderDetails = create(
  persist<OrderDetailsStore>(
    (set) => ({
      deliveryType: DeliveryType.DELIVERY,
      selectedPaymentMethod: undefined,
      deliveryPrice: undefined,
      deliveryCoupon: undefined,
      withoutMoneyBack: false,
      employeeID: undefined,
      setDeliveryType: (deliveryType) => set({ deliveryType: deliveryType }),
      setSelectedPaymentMethod: (paymentMethod) =>
        set({ selectedPaymentMethod: paymentMethod }),
      deliveryInstructions: '',
      setDeliveryInstructions: (instructions) =>
        set({ deliveryInstructions: instructions }),
      action: () => {},
      setAction: (action) => set({ action: action }),
      currentStep: StepType.NONE,
      setCurrentStep: (step) => set({ currentStep: step }),
      wherePaymentType: undefined,
      setWherePaymentType: (wherePaymentType) =>
        set({ wherePaymentType: wherePaymentType }),
      setDeliveryPrice: (price) => set({ deliveryPrice: price }),
      setDeliveryCoupon: (coupon) => set({ deliveryCoupon: coupon }),
      setWithoutMoneyBack(withoutMoneyBack) {
        set({ withoutMoneyBack });
      },
      setEmployeeID(employeeID) {
        set({ employeeID });
      },
    }),
    {
      name: 'order-details',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
