import React, { useState } from 'react'
import { useFormContext, FieldError } from 'react-hook-form'
import cx from 'classnames'

export interface DateTimePickerProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  min?: string
  step?: number
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  name,
  min,
  step,
  onClick,
  onChange,
}) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const [selected, setSelected] = useState<string>('')

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(name, value)
    setSelected(value)
    onChange && onChange(e)
  }

  // Listen to reset calls
  const watchedValue = watch(name)
  React.useEffect(() => {
    setSelected(watchedValue || '')
  }, [watchedValue])

  const error = errors[name] as FieldError | null

  return (
    <input
      type="datetime-local"
      {...register(name)}
      value={selected}
      onChange={handleDateChange}
      min={min}
      step={step}
      onClick={onClick}
      className={cx(
        'px-3 py-2 border rounded-md focus:outline-none w-full focus:border-red-500',
        error ? 'border-red-400' : 'border-gray-400'
      )}
    />
  )
}
