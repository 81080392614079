import { TextInput } from '@onbeef/components/input';
import { currencyMask, DeliveryType } from '@onbeefapp/constants';
import * as React from 'react';
import { useCartStore } from '../../stores/cart';
import { useOrderDetails } from '../../stores/order-details';

interface ListDeliveryPriceAndCouponProps {
  setExtensionCustomCouponCode: (code: string | undefined) => void;
  setExtensionCustomDeliveryFee: (fee: number | string | undefined) => void;
}

const ListDeliveryPriceAndCoupon: React.FC<ListDeliveryPriceAndCouponProps> = ({
  setExtensionCustomCouponCode,
  setExtensionCustomDeliveryFee,
}) => {
  const isExtension = useCartStore((state) => state.isExtension);

  const customDeliveryFeeError = useCartStore(
    (state) => state.customDeliveryFeeError,
  );
  const { deliveryType } = useOrderDetails((state) => ({
    deliveryType: state.deliveryType,
  }));

  const handleChangeCoupon = (e: string) => {
    setExtensionCustomCouponCode(e === '' ? undefined : e);
  };

  const handleChangeDeliveryFee = (e: string | undefined) => {
    setExtensionCustomDeliveryFee(e);
    setCachedDeliveryFee(e);
  };

  const [cachedDeliveryFee, setCachedDeliveryFee] = React.useState<
    number | string | undefined
  >(undefined);

  return (
    isExtension && (
      <React.Fragment>
        <div className="grid grid-cols-2 gap-2 mt-2">
          <>
            <TextInput
              name="coupon"
              customLabel="Cupom de desconto"
              placeholder="Digite seu cupom"
              onChange={(e) => handleChangeCoupon(e.target.value)}
              className="py-2 px-4 pl-2 rounded-md text-xs"
            />
            {deliveryType === DeliveryType.DELIVERY && (
              <TextInput
                name="delivery_fee"
                customLabel="Taxa de entrega"
                placeholder="Digite a taxa de entrega"
                error={customDeliveryFeeError}
                onChange={currencyMask((value) => {
                  handleChangeDeliveryFee(value === '0' ? undefined : value);
                })}
                className="py-2 px-4 pl-2 rounded-md text-xs"
                type="text"
                value={cachedDeliveryFee}
              />
            )}
          </>
        </div>
      </React.Fragment>
    )
  );
};

export default ListDeliveryPriceAndCoupon;
