import { Skeleton } from '@onbeef/components/skeleton';
import { Address, AddressUtils } from '@onbeefapp/constants';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import {
  MapContainer as LeafletMapContainer,
  TileLayer,
  useMapEvents,
} from 'react-leaflet';
import './Map.css';

export interface LeafletAddress {
  lat: string;
  lon: string;
  name: string;
  display_name: string;
  address: {
    road: string; // street_name
    suburb: string; // neighborhood
    city: string;
    state: string;
    region: string;
    postcode: string; // zip_code
    country: string;
  };
}

interface MapContainerProps {
  addressData: any;
  onConfirmAddress: (result: LeafletAddress) => void;
}

export const MapContainer: React.FC<MapContainerProps> = (props) => {
  const mapRef = React.useRef<L.Map | null>(null);
  const [isGettingAddress, setIsGettingAddress] = React.useState(true);
  const [consideredAddress, setConsideredAddress] =
    React.useState<LeafletAddress>();

  const [currentLocation, setCurrentLocation] = React.useState<
    [number, number]
  >([
    props.addressData?.latitude || -16.6632299,
    props.addressData?.longitude || -49.2744486,
  ]);

  const getAddress = async (lat: number, lng: number) => {
    setIsGettingAddress(true);
    try {
      if (!lat || !lng) return;
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`,
      );
      const result: LeafletAddress = await response.json();
      setIsGettingAddress(false);
      if (result) {
        setConsideredAddress(result);
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      setIsGettingAddress(false);
    }
  };

  const LocationEvents = () => {
    const map = useMapEvents({
      dragstart() {
        setIsGettingAddress(true);
      },
      dragend() {
        const center = map.getCenter();
        setCurrentLocation([center.lat, center.lng]);
        getAddress(center.lat, center.lng);
      },
    });

    return null;
  };

  React.useEffect(() => {
    if (props.addressData) {
      getAddress(props.addressData.latitude, props.addressData.longitude);
    }
  }, [props.addressData]);

  return (
    <div className="relative">
      <div className="address-maps-header flex justify-center text-center">
        <div>
          <h2>Você está aqui?</h2>
          <Skeleton
            width={'100%'}
            height={'20px'}
            borderRadius="10px"
            isLoading={isGettingAddress}
          >
            <p>
              {consideredAddress
                ? AddressUtils.getFormattedAddress({
                    street_name: consideredAddress.address?.road,
                    neighborhood: consideredAddress.address?.suburb,
                    city: consideredAddress.address?.city,
                    zip: consideredAddress.address?.postcode,
                    country: consideredAddress.address?.country,
                    state: consideredAddress.address?.state,
                  } as Address)
                : ''}
            </p>
          </Skeleton>
          <small>
            <p>Arraste o mapa para ajustar a localização</p>
          </small>
        </div>
      </div>

      <LeafletMapContainer
        id="map-address"
        center={currentLocation}
        zoom={18}
        style={{ height: '80vh', width: '100%' }}
        ref={mapRef}
      >
        <>
          <div className="map-pin">
            <img
              src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png"
              alt="Map Pin"
              style={{ width: '25px', height: '41px' }}
            />
          </div>

          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <LocationEvents />
        </>
      </LeafletMapContainer>
      <div
        className="w-full absolute flex justify-center z-[9999]"
        style={{ marginTop: '-60px' }}
      >
        {!isGettingAddress && (
          <button
            type="button"
            className="min-w-5 p-2 text-contrastText bg-primary rounded-md text-sm flex justify-center"
            onClick={() => {
              props.onConfirmAddress(consideredAddress!);
            }}
          >
            Confirmar localização
          </button>
        )}
      </div>
    </div>
  );
};
