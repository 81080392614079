import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import api from '../../services/api';
import { Assessment, ListOrdersResponse, Motive } from './types';
import { OrderDetails } from '@onbeefapp/constants';

interface ListOrdersParams {
  page: number;
  merchant_id: string;
}

async function listOrders({ page, merchant_id }: ListOrdersParams) {
  const { data } = await api.get<ListOrdersResponse>(
    '/customers/orders/resume?page=' + page,
    {
      headers: { merchantId: merchant_id },
    },
  );
  return data;
}

async function getOrderDetail(ctx: QueryFunctionContext) {
  const [, id] = ctx.queryKey;
  if (!id) {
    return;
  }
  const { data } = await api.get<OrderDetails>(
    '/customers/orders/resume/' + id,
  );
  return data;
}

async function getOrderDetailMerchant(ctx: QueryFunctionContext) {
  const [, id] = ctx.queryKey;
  if (!id) {
    return;
  }
  const { data } = await api.get<OrderDetails>('/merchants/orders/' + id);
  return data;
}

async function getMotives(_ctx: QueryFunctionContext) {
  const { data } = await api.get<Motive[]>('/motives');
  return data;
}

type RegisterAssessmentParams = Omit<Assessment, 'motives' | 'order_id'> & {
  motives: string[];
  order_id?: string;
  order_code?: string;
  phone?: string;
};

async function registerAssessment(assessment: RegisterAssessmentParams) {
  const { data } = await api.post('/customers/assessment', { ...assessment });
  return data;
}

async function registerUnloggedAssessment(
  assessment: RegisterAssessmentParams,
) {
  const { phone, ...payload } = assessment;
  if (!phone) return;
  const { data } = await api.post(`/customers/${phone}/assessment`, payload);
  return data;
}

async function checkHasUnloggedAssessment(phone?: string, code?: string) {
  if (!phone || !code) return;
  const { data } = await api.get<{ has_assessment: boolean; message?: string }>(
    `/customers/${phone}/orders/${code}/assessment`,
  );
  return data;
}

export function useListOrders() {
  return useMutation(['listOrders'], listOrders);
}

export function useGetOrderDetail(id?: string) {
  return useQuery(['getOrderDetail', id], getOrderDetail, {
    retry: 1,
    refetchOnWindowFocus: false,
  });
}

export function useGetOrderDetailMerchant(id?: string) {
  return useQuery(['getOrderDetail', id], getOrderDetailMerchant, {
    retry: 1,
  });
}

export function useGetMotives() {
  return useQuery(['getMotives'], getMotives, {
    refetchOnWindowFocus: false,
  });
}

export function useRegisterAssessment() {
  return useMutation(['registerAssessment'], registerAssessment);
}

export function useRegisterUnloggedAssessment() {
  return useMutation(
    ['registerUnloggedAssessment'],
    registerUnloggedAssessment,
  );
}

export function useCheckHasUnloggedAssessment(phone?: string, code?: string) {
  return useQuery(
    ['checkHasUnloggedAssessment', phone, code],
    () => checkHasUnloggedAssessment(phone, code),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
}
